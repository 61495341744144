import { App, URLOpenListenerEvent } from "@capacitor/app";
import { AuthService } from "ionic-appauth";
import { CapacitorStorage, CapacitorBrowser } from "ionic-appauth/lib/capacitor";
import { isPlatform } from "@ionic/vue";
import AxiosRequestor from "./axiosRequestor";
import CapacitorRequestor from "./capacitorRequestor";

export default class Auth {
	private static authService: AuthService | undefined;

	public static browser = new CapacitorBrowser();

	private static buildAuthInstance() {
		const requestor = isPlatform("ios") ? new CapacitorRequestor() : new AxiosRequestor();
		const authService = new AuthService(this.browser, new CapacitorStorage(), requestor);

		authService.authConfig = {
			client_id: import.meta.env.VITE_AUTH_CLIENT_ID ?? "",
			server_host: import.meta.env.VITE_AUTH_BASE_URL ?? "",
			redirect_url: isPlatform("capacitor")
				? isPlatform("ios")
					? "msauth." + import.meta.env.VITE_AUTH_APP_ID + "://auth"
					: import.meta.env.VITE_AUTH_APP_ID + "://callback"
				: window.location.origin + "/authcallback",
			end_session_redirect_url: isPlatform("capacitor")
				? isPlatform("ios")
					? "msauth." + import.meta.env.VITE_AUTH_APP_ID + "://auth"
					: import.meta.env.VITE_AUTH_APP_ID + "://endSession"
				: window.location.origin + "/endsession",
			scopes: import.meta.env.VITE_AUTH_SCOPES ?? "",
			pkce: true,
		};

		if (isPlatform("capacitor")) {
			App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
				if (event.url.indexOf(authService.authConfig.redirect_url) === 0) {
					authService.authorizationCallback(event.url);
				} else if (event.url.includes("iedereendigitaal.mintus.be")) {
					const url = new URL(event.url);
					const slug = url.pathname;

					if (slug) {
						document.location.href = slug;
					}
				} else {
					authService.endSessionCallback();
				}
			});
		}

		authService.init();

		return authService;
	}

	public static get Instance(): AuthService {
		if (!this.authService) {
			this.authService = this.buildAuthInstance();
		}

		this.authService.events$.pipe().subscribe((action) => {
			if (action.error) {
				console.error("SSO", action);
			}
		});

		return this.authService;
	}
}
