export default {
	HOME_PATH: "/tabs/tasks",
	HOME_NAME: "tasks",
	APPS_PATH: "/tabs/applications",
	APPS_NAME: "applications",
	HELP_PATH: "/faq",
	HELP_NAME: "UnauthenticatedHelp",
	LOGIN_PATH: "/login",
	LOGIN_NAME: "Login",
	AUTH_CALLBACK_PATH: "/authcallback",
	AUTH_CALLBACK_NAME: "RedirectPage",
	END_SESSION_PATH: "/endsession",
	END_SESSION_NAME: "EndRedirectPage",
	ADMIN_LOGIN_PATH: "/admin-login",
	ADMIN_LOGIN_NAME: "AdminLogin",
};
